<template>
  <div v-if="ok" class="col-12 col-lg-6 c g">
    <div class="card">
      <div class="card-body">
        <div class="col-12 text-center" v-if="loading">
            <img src="/files/loading.svg" alt="">
        </div>
        <h4 class="text-center" v-if="!loading && exdate != ''">
          ينتهي الاشتراك في {{ exdate }}
        </h4>
        <div class="col-12 text-center g">
          <button class="btn btn-primary" @click="$router.push('/subscribe')">للاشتراك / لتجديد الاشتراك اضغط هنا</button>
        </div>
      </div>
      <div class="card-footer" v-if="list.length">
        <div class="col-12 table-responsive">
          <p>طلبات الاشتراك</p>
          <table class="table table-sm table-bordered">
            <thead>
              <th>
                رقم الطلب
              </th>
              <th>
                تاريخ الانتهاء
              </th>
              <th>
                الفاتورة
              </th>
            </thead>
            <tbody>
              <tr v-for="order in list" :key="order._id">
                <td>
                  {{ order.order_id }}
                </td>
                <td>
                  {{ order.exdate }}
                </td>
                <td>
                  <a :href="`https://server.naqar.net/f/naqar.net-${order.order_id}.pdf`" target="_blank">
                  <i class="fa fa-file"></i>
                  عرض الفاتورة</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      ok: false,
      loading: true,
      list: [],
      exdate: ""
    }
  },
  created(){
    var g = this;
    if(localStorage.getItem("school_id") && localStorage.getItem("school_id") != "null"){
      g.ok = true;
      this.loading = true;
      $.post(api + '/api/subscribe/home', {
          school_id: localStorage.getItem("school_id")
      })
      .then(function(response){
          g.loading = false
          if(response.status == 100){
              g.exdate = response.response.exdate
              g.list = response.response.list
          }
      })
      .fail(function(){
          g.loading = false
      })
    }
  }
}
</script>

<style>

</style>